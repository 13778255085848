
import { Component, Watch, Prop, Vue } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import { FormInput } from "@/components/forms";
import type { IndexActivityPayload } from "@/store/modules/activity.store";
import { Debounce } from "@/utils/vue-helpers";
import { activityIsDossier } from "@/utils/model-helpers";

@Component({
    components: {
        FormInput,
        Spinner: () => import("@/components/general/spinner.vue"),
    },
})
export default class PageActivitiesSearch extends Vue {
    @Getter("auth/me") me!: CurrentAccount;
    @Getter("activity/all") activities!: Activity[];
    @Getter("activity/total") total!: number;

    @Action("activity/index") indexActivities!: (payload: IndexActivityPayload) => Activity[];
    @Action("activity/clear") clearActivities!: () => void;
    @Action("activity/clearViewing") clearViewingActivity!: () => void;

    query: string = "";
    loaded: boolean = false;
    recentActivities: Array<Activity> = [];
    favoriteActivities: Array<Activity> = [];
    activityIsDossier = activityIsDossier

    mounted() {
        this.recentActivities = JSON.parse(localStorage.getItem("recent_activities")!) || [];
    }

    @Debounce(200)
    @Watch("query")
    handleQueryChanged() {
        if (this.query === "") {
            this.clearActivities();
        } else {
            this.loaded = false;
        }
    }

    @Debounce(500)
    loadMore(after: boolean) {
        this.loaded = false;
        // @ts-ignore
        this.indexActivities({ query: this.query, after: after ? this.lastAfter : null }).finally(() => {
            this.loaded = true;
        });
    }

    activitiesScroll(e: Event) {
        if (this.total !== this.activities.length) {
            // @ts-ignore
            const activitiesSidebar: HTMLElement | undefined = this.$refs.activities;
            const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

            if (activitiesSidebar) {
                if (activitiesSidebar.scrollTop + vh >= activitiesSidebar.getBoundingClientRect().bottom + activitiesSidebar.scrollTop) {
                    this.loadMore(true);
                }
            }
        }
    }

    handleActivityClicked(activity: Activity) {
        let recentActivities = [];
        recentActivities = JSON.parse(localStorage.getItem("recent_activities")!) || [];

        if (recentActivities.length === 0 || !recentActivities.find((a: Activity) => a.id === activity.id)) {
            recentActivities.push(activity);
            localStorage.setItem("recent_activities", JSON.stringify(recentActivities));
        }
        const isDossier = activityIsDossier(activity);
        this.$router.push({ name: isDossier ? "file-detail" : "prospect-detail", params: { id: activity.id.toString() } });
    }

    clearSearch(activity: Activity) {
        let recentActivities = [];
        recentActivities = JSON.parse(localStorage.getItem("recent_activities")!) || [];

        if (recentActivities.length > 0) {
            const index = recentActivities.findIndex((a: Activity) => a.id === activity.id);

            if (index >= 0) {
                recentActivities.splice(index, 1);
                localStorage.setItem("recent_activities", JSON.stringify(recentActivities));
                this.recentActivities = recentActivities;
            }
        }
    }

    get lastAfter(): Array<string | number> {
        const total = this.activities.length;
        const last: Activity = this.activities[total - 1];
        if (last && last.sort) {
            return last.sort;
        }

        return [];
    }
}
